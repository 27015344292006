// Generated by Framer (19eed98)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["lbnGsbzWa", "LQkQ3Cw9q"];

const serializationHash = "framer-PDddq"

const variantClassNames = {lbnGsbzWa: "framer-v-fdjns5", LQkQ3Cw9q: "framer-v-1amdk14"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "lbnGsbzWa", "Variant 2": "LQkQ3Cw9q"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "lbnGsbzWa"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "lbnGsbzWa", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-PDddq", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-fdjns5", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"lbnGsbzWa"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({LQkQ3Cw9q: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><SVG className={"framer-6g0lxz"} data-framer-name={"Arrow"} layout={"position"} layoutDependency={layoutDependency} layoutId={"kmPsrPvL7"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 16 16\"><path d=\"M 13.667 1.583 L 4 1.583 C 3.586 1.583 3.25 1.919 3.25 2.333 C 3.25 2.747 3.586 3.083 4 3.083 L 11.856 3.083 L 1.803 13.136 C 1.51 13.429 1.51 13.904 1.803 14.197 C 2.096 14.49 2.571 14.49 2.864 14.197 L 12.917 4.144 L 12.917 12 C 12.917 12.414 13.252 12.75 13.667 12.75 C 14.081 12.75 14.417 12.414 14.417 12 L 14.417 2.333 C 14.417 1.919 14.081 1.583 13.667 1.583 Z\" fill=\"var(--token-be079bad-eabd-4234-8a56-5579c7057187, rgb(0, 0, 0)) /* {&quot;name&quot;:&quot;Primary / Black&quot;} */\"></path></svg>"} svgContentId={1342450132} withExternalLayout {...addPropertyOverrides({LQkQ3Cw9q: {svg: "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 16 16\"><path d=\"M 13.667 1.583 L 4 1.583 C 3.586 1.583 3.25 1.919 3.25 2.333 C 3.25 2.747 3.586 3.083 4 3.083 L 11.856 3.083 L 1.803 13.136 C 1.51 13.429 1.51 13.904 1.803 14.197 C 2.096 14.49 2.571 14.49 2.864 14.197 L 12.917 4.144 L 12.917 12 C 12.917 12.414 13.252 12.75 13.667 12.75 C 14.081 12.75 14.417 12.414 14.417 12 L 14.417 2.333 C 14.417 1.919 14.081 1.583 13.667 1.583 Z\" fill=\"var(--token-e16847f2-b192-48e1-a890-7bbcce11cc6e, rgb(255, 255, 255)) /* {&quot;name&quot;:&quot;Primary / White&quot;} */\"></path></svg>", svgContentId: 318331668}}, baseVariant, gestureVariant)}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-PDddq [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PDddq .framer-fe6ctj { display: block; }", ".framer-PDddq .framer-fdjns5 { height: 16px; overflow: hidden; position: relative; width: 16px; }", ".framer-PDddq .framer-6g0lxz { flex: none; height: 16px; left: 0px; position: absolute; top: 0px; width: 16px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 16
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"LQkQ3Cw9q":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerJg5ItQVAC: React.ComponentType<Props> = withCSS(Component, css, "framer-PDddq") as typeof Component;
export default FramerJg5ItQVAC;

FramerJg5ItQVAC.displayName = "Icon Arrow";

FramerJg5ItQVAC.defaultProps = {height: 16, width: 16};

addPropertyControls(FramerJg5ItQVAC, {variant: {options: ["lbnGsbzWa", "LQkQ3Cw9q"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerJg5ItQVAC, [])